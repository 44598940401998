<template>
  <div>

    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />

  </div>
</template>
<script>
// import * as utils from '../api/utils'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      active: 0,
      dotShow: null,
      num: 2,
      testObj: {

      }
    }
  },
  updated () {
    // this.dispatchMsgBadge()
  },
  mounted () {
    // this.dispatchMsgBadge()
  },
  computed: {
    ...mapState({
      // accountRole: state => state.accountRole.accountRole,
      accountRole: state => state.accountRole,
      accountRole2: 'accountRole',
      thisTest () {
        return this.num * 2
      }
    }),
    ...mapState([
      'accountRole'
    ]),
    ...mapState('userInfo', ['userInfo']),
    testlg () {
      console.log('this.accountRole.accountRole', this.accountRole.accountRole)
      return 'testlg'
    }
  },
  methods: {
    // dispatchMsgBadge () {
    //   if (!this.$store.state.msgBadge.msgBadge) {
    //     this.$store.dispatch('MSGBADGEACTION')
    //   }
    // }
  }
}
</script>
<style scoped lang="scss">
.tabBox {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
